<template>
    <div class="shopSelect">
        <div class="shopContainer">
            <div class="breadNav">
                <div class="nav_info flex">您的位置：首页 > 广告公司</div>
            </div>
            <div class="distance">
                <div class="address">
                    <div class="address_sesect flex align-center">
                        <CitySelect @change="cityChange"></CitySelect>
                        <div @click="sortDistance" class="flex align-center">
                        </div>
                    </div>
                </div>
            </div>
            <!-- 内容 -->
            <div class="m20 w100">
                <div :class="{'shopList':active,'ifAir1':air}">
                    <ul>
                        <li :key="i" v-for="(item,i) in merchantList">

                            <div class="liTop">
                                <div class="imgWrap">
                                    <img :src="item.logoUrl | imagePath" @click="goshop(item.id)" alt="">
                                </div>
                                <div class="listWrap">
                                    <div class="listWrap-rightl">
                                        <div class="listWrap-top">
                                            <h2 @click="goshop(item.id)">{{item.storeName}}</h2>
                                            <span @click="goshop(item.id)" class="goshop">进入店铺</span>
                                            <span class="adressList">{{item.address}}</span>
                                        </div>
                                        <div class="listWrap-bottom" v-if="item.service.length!=0">
                                            <span>擅长服务：</span>
                                            <span :key="i2" class="design"
                                                  v-for="(item2,i2) in item.service">{{item2}}</span>
                                        </div>
                                    </div>
                                    <div class="bthWrap">
                                        <button @click="dialogVisible = true;phone(item.id)" type="button">联系商家</button>
                                        <button @click="dialogVisible2 = true;oneKeyShow(item.storeName,item.id)" class="shopAd-btn"
                                                type="button">一键支付
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>


                <div class="quickOrder">
                    <el-dialog :visible.sync="dialogVisible" title="">
                        <div class="popTop">
                            <p class="storeName">{{store.storeName}}</p>
                            <div class="storeMobile">
                                <div>
              <span>
                <img align="middle" alt="" src="../../assets/image/phone.png">
              </span>
                                    <span>{{store.mobile}}</span>
                                </div>
                                <div class="streFooter">
                                    注：为了您的交易安全，请走孔雀有礼线上交易如遇可疑商家，可及时向平台投诉举报！
                                </div>
                            </div>
                        </div>
                    </el-dialog>
                </div>

                <div class="pagtion">
                    <el-pagination
                            :current-page="pageNum"
                            :page-sizes="[10, 20, 30, 40]"
                            :total="totalSize"
                            @current-change="currentChangePage"
                            @size-change="sizeChangePage"
                            layout="total, sizes, prev, pager, next, jumper"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>
        <div class="quickOrder2">
            <el-dialog :visible.sync="dialogVisible2" title="">
                <div class="popWrap">
                    <div class="titles">
                        <p>一键下单</p>
                        <p>您已经与商家沟通好价格，直接付款</p>
                        <p>可快速生成订单</p>
                    </div>
                    <el-form :model="form" ref="form">
                        <el-form-item label=" 我需要：" prop="consignee">
                            <el-input
                                    placeholder="简要说明您需要做的业务（包括物料、数量、工艺、服务要求等）"
                                    type="textarea"

                                    v-model="form.info">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="订单金额：" prop="mobile">
                            <el-input @input="oninput" maxlength="11" placeholder="请输入与商家已沟通好的价格（元）"
                                      v-model="form.price"></el-input>
                        </el-form-item>

                        <div class="protocol">
                            <el-checkbox @change="checkedAll" v-model="checked">
                                <span>我已阅读并同意&nbsp;</span>
                            </el-checkbox>
                            <span class="protocol-word">《孔雀有礼使用协议》</span>
                        </div>
                        <el-form-item class="save" v-if="isDisable">
                            <el-button class="details" disabled type="primary">立即提交</el-button>
                        </el-form-item>
                        <el-form-item class="save" v-if="isSave">
                            <el-button @click="onSubmit1" class="details" type="primary">立即提交</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </el-dialog>
        </div>
    </div>
</template>
<script>
    import * as api from '@/api/api';
    import LevelsMenu from '@/components/common/levelsMenu'
    import CitySelect from '@/components/common/citySelect'
    import Pagination from '@/components/common/pagination'
    import {mapMutations} from 'vuex'

    export default {
        components: {Pagination, LevelsMenu, CitySelect},
        data() {
            return {
                areaId: "",
                checked: true,
                isSave: true, //保存可用
                isDisable: false, //保存可用
                ShopListContent: [], //分类搜索店铺列表内容
                form: {
                    info: '', //备注信息
                    price: "",
                },
                dialogVisible2: false, //一键支付弹窗显示与否
                // acode: JSON.parse(localStorage.getItem("cityWrap")).cid, //城市idcode
                acode: "320100", //城市idcode

                distance: '',

                store: {}, //保存弹窗信息的对象
                dialogVisible: false, //电话弹窗显示与否
                totalSize: 0, //总条数
                active: true,
                air: false,
                pageNum: 0, //展示第几页
                pageSize: 10, //一页展示几条
                merchantList: [] //搜索详情展示数据
            }
        },
        mounted() {
            this.shopList();
            console.log(JSON.parse(localStorage.getItem("cityWrap")).cid);
            // this.getList();
        },
        // watch:{
        //   dialogVisible(val){
        //       if(this.dialogVisible){
        //         document.getElementsByClassName("header")[0].classList.add("addPopClass");
        //       }else{
        //         document.getElementsByClassName("header")[0].classList.remove("addPopClass");
        //       }
        //   }
        // },
        methods: {
            // 通过正则过滤小数点后两位
            oninput(e) {
                this.form.price = (this.form.price.match(/^\d*(\.?\d{0,2})/g)[0]) || null
            },
            //以下一键支付下单
            checkedAll(e) { //选中协议
                if (!e) {
                    this.isDisable = true;
                    this.isSave = false;
                }
                if (e) {

                    this.isDisable = false;
                    this.isSave = true;
                }
            },
            oneKeyShow(storeName, storeId) { //点击一键支付时，保存店铺信息
                if (this.isLogin == "0") {
                    this.$message.error('请先登录!');
                    this.dialogVisible2 = false;
                    this.$router.push("/login");
                }
                this.fastList = {
                    "storeName": storeName,
                    "storeId": storeId,
                }
            },
            onSubmit1() { //一键支付
                if (this.form.price == "") {
                    this.$message.error('请输入订单金额!');
                    return;
                }
                if (!this.$check.checkMoney(this.form.price)) {
                    this.$message.error("请输入订单金额!");
                    return;
                }
                //  else{
                //     var reg= /^-?\d+$/;
                //     if(!reg.test(this.form.price)){
                //         this.$message.error('输入金额需为纯数字!');
                //         return;
                //     }
                // }

                this.fastList.info = this.form.info; //需求信息
                this.fastList.price = this.form.price;//一键支付输入的价格
                this.fastList.fastFlag = "2";
                this.fastList.img = "upload/b060a4a7810743fa8ea8cdc56d124138.png";
                this.fastList.name = "线下业务单";
                this.fastList.sn = "";
                localStorage.setItem("fastList", JSON.stringify(this.fastList));
                this.$router.push({
                    name: "order",
                });
            },
            phone(storeId) { //联系商家

                this.$http.get('store/info', {params: {storeId: storeId}}).then(res => {
                    this.store = res.data.data;
                })
            },
            //以下分页
            //选择每页显示条数触发
            sizeChangePage(val) {
                let self = this;
                self.pageSize = val;
                // console.log(`每页 ${val} 条`);
                self.shopList(this.pageSize); //请求接口
            },
            //选择第几页触发
            currentChangePage(val) {
                // console.log(`当前页: ${val}`);
                let self = this;
                self.pageNum = val;
                self.shopList(this.pageNum);
            },
            seeDetails(storeId, productId) { //搜索列表进店铺
                this.$router.push({
                    path: "productDetail",
                    query: {
                        sid: storeId,
                        pid: productId
                    }
                })
            },
            goshop(storeId) {
                this.$router.push({
                    name: "shopPage",
                    params: {
                        id: storeId
                    }
                })
            },
            ...mapMutations({saveStore: 'STORE_INFO'}),
            sortDistance() {
                this.distance = this.distance === 'asc' ? 'desc' : 'asc';
                this.shopList()
            },
            /**
             * @description: 分页事件
             * @param {type}
             * @return:
             */
            getNum(val) {
                this.pageNum = val;
                this.shopList()
            },
            /**
             * @description:查询列表
             * @param {type}
             * @return:
             */
            shopList() {
                let req = {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    distance: this.distance,
                    areaId: this.areaId,
                    lat1: localStorage.getItem('lat') === false ? "" : localStorage.getItem('lat') === undefined ? "" : localStorage.getItem('lat'),
                    lon1: localStorage.getItem('lng') === false ? "" : localStorage.getItem('lng') === undefined ? "" : localStorage.getItem('lng'),
                    // lat1: localStorage.getItem('lat') + '',
                    // lon1: localStorage.getItem('lng') + ''
                };
                if (localStorage.getItem('lat') != "320100") {
                    req.acode = JSON.parse(localStorage.getItem('adcode'));
                } else {
                    req.acode = this.acode;
                }

                this.$http.post('/store/getsuperiorSellers', req).then(res => {
                    // debugger
                    // const { data, totalSize } = res.data.data
                    this.merchantList = res.data.data.data;
                    this.totalSize = res.data.data.totalSize
                })
            },
            cityChange(e) { //选择地区触发事件
                if (JSON.stringify(e.proviceItem) != '{}') { //如果选择的是省，把当前的acode传给后台获取数据
                    this.areaId = e.proviceItem.id
                }
                if (JSON.stringify(e.cityItem) != '{}') { //如果选择的是市，把当前的acode传给后台获取数据
                    this.areaId = e.cityItem.id
                }
                if (JSON.stringify(e.regionItem) != '{}') { //如果选择的是区，把当前的acode传给后台获取数据
                    this.areaId = e.regionItem.id
                }
                // if(JSON.stringify(e.proviceItem) != '{}'){ //如果选择的是省，把当前的acode传给后台获取数据
                //   this.acode = e.proviceItem.adcode
                // }
                // if(JSON.stringify(e.cityItem) != '{}'){ //如果选择的是市，把当前的acode传给后台获取数据
                //   this.acode = e.cityItem.adcode
                // }
                // if(JSON.stringify(e.regionItem) != '{}'){ //如果选择的是区，把当前的acode传给后台获取数据
                //   this.acode = e.regionItem.adcode
                // }
                this.shopList()
            },
            goShopindex(item) {
                this.saveStore(item);
                this.$router.push({name: 'shopPage', params: {id: item.id}})
            }
        }
    }
</script>
<style lang="scss" scoped>
    * {
        margin: 0;
        padding: 0;
    }

    .content-top {
        ul {
            margin: 0 20px;

            li {
                width: 100%;
                min-height: 51px;
                padding: 10px 0;
                list-style: none;
                line-height: 35px;
                font-size: 14px;
                border-bottom: 1px dashed #e5e5e5;
                color: #333;
                display: flex;
                justify-content: flex-start;

                em {
                    height: 35px;
                    margin-right: 40px;
                    line-height: 35px;
                }

                > div {
                    max-width: 1050px;
                    display: flex;
                    flex-wrap: wrap;

                    span {
                        margin-right: 40px;
                    }
                }
            }

            li:last-child {
                border: none;
            }
        }

        .sort {
            width: 100%;
            min-height: 51px;
            padding: 10px 20px;
            line-height: 35px;
            // background: red;
            font-size: 14px;
            color: #333;
            border: 10px solid #f5f5f5;
            border-left: none;
            border-right: none;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;

            em {
                margin-right: 40px;
            }

            span {
                margin-right: 40px;
            }
        }

    }


    .m20 {
        margin-top: 20px;
        padding: 20px;
        background-color: #fff;
    }

    .shopContainer {
        width: 100%;
        max-width: 1200px;
        min-height: 360px;
        margin: 0 auto;

        .breadNav {
            padding: 10px 0;
        }

        .distance {
            label {
                margin-right: 20px;
                min-width: 30px;
            }
        }

        .content {
            padding: 20px;
            border-bottom: 1px solid #ddd;
            background: #fff;

            img {
                width: 72px;
                height: 72px;
            }
        }
    }

    .pagation {
        text-align: center;
        padding: 30px 0;
    }

    .shopSelect {
        width: 100%;
        // padding: 10px 20px;
        background: #f5f5f5;
    }

    .listMiddle {
        margin: 0 45px 0 27px;

        .top {
            width: 100%;
            margin-bottom: 19px;

            .name {
                padding-right: 15px;
                color: #333;
                font-size: 18px;
            }

            .zhenIcon {
                width: 20px;
                height: 22px;
            }

            .shopTag {
                padding: 0 14px 0 16px;

                span {
                    padding: 5px;
                    border: 1px solid #ddd;
                    text-align: center;
                    color: #666;
                    font-size: 14px;
                    margin-right: 14px;
                }
            }
        }

        .bottom {
            .addIcon {
                width: 15px;
                height: 17px;
            }

            span {
                color: #666;
                font-size: 15;
            }

            .shopAddress {
                border-right: 1px solid #ddd;
                padding-right: 30px;
                margin-right: 30px;

                .shopText {
                    font-size: 15px;
                    padding-left: 13px;
                }
            }
        }
    }

    .shopBtn {
        height: 30px;
        border: 1px solid #0db168;
        color: #0db168;
        padding: 0px 10px;
        border-radius: 5px;
        text-align: center;
        outline: none;
        background-color: #fff;
        cursor: pointer;

        &:hover {
            background: #0db168;
            color: #fff;
        }
    }


    .pagtion {
        display: flex;
        justify-content: center;
        margin-top: 35px;
        margin-bottom: 40px;
    }


    //以下是店铺列表部分样式
    .shopList {
        width: 100%;

        // background: red;
        li {

            border-bottom: 1px solid #f5f5f5;

            height: 100%;
            display: flex;
            flex-direction: column;

            .imgWrap {
                width: 124px;
                height: 100%;
                // background: pink;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    display: block;
                    width: 80px;
                    height: 80px;
                    cursor: pointer;
                }
            }

            .listWrap {
                width: 100%;
                height: 100%;
                padding-left: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .listWrap-rightl {
                    width: 860px;
                    // background: green;

                    .listWrap-top {
                        display: flex;
                        justify-content: flex-start;
                        height: 50px;
                        align-items: center;

                        h2 {
                            font-size: 18px;
                            color: #333;
                            margin-right: 20px;
                            cursor: pointer;
                        }

                        span {
                            margin-right: 20px;
                        }

                        .goshop {
                            border: 1px solid #ff9800;
                            color: #ff9800;
                            text-align: center;
                            font-size: 14px;
                            padding: 5px;
                            cursor: pointer;
                        }

                        .adressList {
                            padding-left: 22px;
                            background: url("../../assets/image/dingwei.png") no-repeat 0 2px;

                        }
                    }

                    .listWrap-bottom {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        min-height: 45px;

                        .design {
                            padding: 6px 9px;
                            font-size: 14px;
                            padding: 6px 9px;
                            border: 1px solid #e5e5e5;
                            color: #666;
                            margin-right: 20px;
                            cursor: rt;
                        }

                        span {
                            margin-right: 20px;
                        }
                    }
                }

                .bthWrap {
                    > button {
                        width: 100px;
                        background: #d82229;
                        color: #fff;
                        font-size: 14px;
                        text-align: center;
                        line-height: 34px;
                        border-radius: 3px;
                        margin-top: 10px;
                        border: none;
                        cursor: pointer;
                    }

                    > :first-child {
                        background: none;
                        border: 1px solid #d82229;
                        color: #d82229;
                        margin-top: 0;
                        margin-bottom: 10px;
                    }
                }


            }

            .liTop { //li上半部分
                height: 132px;
                display: flex;
                justify-content: flex-start;
            }
        }
    }


    //以下弹窗样式
    .quickOrder {


        /deep/ .el-dialog {
            width: 440px;
            height: 220px;
            margin-top: 40vh !important;

            .el-dialog__body {
                padding: 0;
            }

            .el-dialog__header {
                background: #f5f5f5;
            }

            .popTop {
                height: 120px;

                .storeName {
                    text-align: center;
                    font-size: 22px;
                    color: #333;
                    height: 44px;
                    line-height: 44px;
                    background: #f5f5f5;
                }

                .storeMobile {
                    height: 66px;
                    line-height: 66px;
                    font-size: 28px;
                    color: #333;
                    background: #f5f5f5;

                    > div {
                        width: 280px;
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        margin: 0 auto;
                        padding: 0 10px;
                        overflow: hidden;
                        color: #333333;

                        img {
                            display: block;
                        }
                    }

                    .streFooter {
                        text-align: center;
                        height: 80px;
                        font-size: 12px;
                        color: #999;
                        display: flex;
                        justify-content: center;
                        line-height: 24px;
                    }
                }
            }

        }
    }

    /deep/ .quickOrder2 {
        /deep/ .el-button--primary {
            width: 100%;
            height: 100%;
            background: #d82229;
            border: none;
        }

        .el-form-item__content {
            width: 100%;
        }

        .titles {
            font-size: 16px;

            :last-child {
                color: #d82229;
            }
        }

        .el-textarea {
            width: 100%;
            height: 180px;
        }

        .el-textarea__inner {
            width: 100%;
            height: 180px;
            resize: none;
        }

        .el-dialog {
            height: auto;
            padding-bottom: 40px;
        }

        .el-input {
            width: auto;
        }

        .titles {
            display: flex;
            justify-content: flex-start;
            border-bottom: 1px solid #eee;
            margin-bottom: 20px;
            height: 48px;

            :nth-child(1) {
                margin-right: 20px;
                line-height: 20px;
                font-size: 20px;
                color: #333;
                font-weight: 900;
            }

            :nth-child(2) {
                font-size: 16px;
                color: #333;
            }
        }

        .el-form-item {
            display: flex;
            justify-content: flex-start;
            margin-bottom: 20px;

            .el-form-item__label {
                width: 82px !important;
                flex-shrink: 0;
                font-size: 14px;
                color: #333;
            }

            .el-input__inner {
                width: 540px;
            }
        }

        /deep/ .el-dialog {
            width: 720px;

            .el-dialog__body {
                padding: 0 50px;

                .titles {
                    display: flex;
                    justify-content: flex-start;
                    border-bottom: 1px solid #eee;
                    margin-bottom: 20px;
                    height: 48px;

                    :nth-child(1) {
                        margin-right: 20px;
                        line-height: 20px;
                        font-size: 20px;
                        color: #333;
                        font-weight: 900;
                    }

                    :nth-child(2) {
                        font-size: 16px;
                        color: #333;
                    }
                }

                .el-form-item {
                    display: flex;
                    justify-content: flex-start;
                    margin-bottom: 20px;

                    .el-form-item__label {
                        width: 82px !important;
                        flex-shrink: 0;
                        font-size: 14px;
                        color: #333;
                    }

                    .el-input__inner {
                        width: 540px;
                    }
                }

                .protocol {
                    display: flex;
                    margin-bottom: 30px;
                    margin-left: 79px;

                    .protocol-word {
                        color: #d82229;
                    }
                }

                .el-checkbox__input.is-checked + .el-checkbox__label {
                    color: #999;
                }

                .is-disabled {
                    width: 100%;
                    height: 100%;
                }

                .el-checkbox {
                    color: #999;
                }

                .save {
                    display: flex;

                    .el-form-item__content {
                        width: 540px;
                        height: 45px;
                        color: #fff;
                        font-size: 16px;
                        background: #d82229;
                        // margin: 0 auto;
                        border-radius: 4px;
                        margin-left: auto;
                        cursor: pointer;

                        .el-button {
                            width: 100%;
                            height: 100%;
                            background: #d82229;
                            border: none;
                        }
                    }
                }
            }

            .is-disabled {
                background: #d82229;
                border: none;
                opacity: .5;
            }

            // .el-button--primary{
            //   width: 100%;
            //   height:100%;
            //   background: #d82229;
            //   border:none;
            //   // color: #fff;
            // }
        }
    }

    //  按钮重置
    button {
        -moz-user-select: none; /*火狐*/
        -webkit-user-select: none; /*webkit浏览器*/
        -ms-user-select: none; /*IE10*/
        /*-khtml-user-select:none;!*早期浏览器*!*/
        user-select: none;
    }
</style>


